import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
// import { Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';
import * as Sentry from "@sentry/react";

import Emitter from './eventemitter';

import maTheme from './theme';
import Routes from './routes/Routes';
import { 
  DefaultRunidContext, 
  ServerVersionContext, 
  ServerIsAvailableContext, 
  LoadConstants, 
  ConstantsContext,
  IsLoadingContext,
  OpeningStatusContext,
  AutodispatchContext,
  MappingContext,
  GetHeaders,
  SOCKETIO_ENDPOINT, 
  API_ROOT
} from './config.js';

import _ from 'lodash';

import { SnackbarProvider } from 'notistack'; // SnackbarProvider: global snackbar provider
// import { SnackbarCloseButton } from './components/SnackbarCloseButton.js';
import { ApiUiProvider } from './components/ApiCall.js';
import { AuthContext, SetUser } from './Auth.js';

import PageLoading from './pages/auth/PageLoading';

import axios from 'axios';
import socketIOClient from 'socket.io-client';

const _debug = true;
const SOCKETS_FOR_EMITTER = [
  'update_dashboard_stats', 
  'run_update', 
  'order_update', 
  'product_change', 
  'tour_update', 
  'user_update'
]

function rtUsersAccountGet(setCurrentuser){    
  axios({
    url: `${API_ROOT}/users/account`,
    headers: GetHeaders(),
    method: 'GET'
  }).then((r) => {
    // console.log('axios user r', r)
    if(r.data && r.data.User){
      // console.log('retrieved user', r.data.User)
      SetUser(r.data.User);
      setCurrentuser(r.data.User); // for App() only


      Sentry.configureScope(scope => {
        scope.setUser({
          userid: r.data.User.userid,
          extra: {
            // is_admin: r.data.
          }
        });
      });


    }
  }).catch((e) => {
    console.debug('rtUsersAccountGet error', e)
    if(e.response && e.response.status === 401){
      console.log('App -> rtUsersAccountGet Logout')
      setCurrentuser(null);
      SetUser(null);
    }
  }).finally(() => {
  });
}


// let is_available = false;
// function setIsAvailable(v){ is_available = v }

let socket = null;

function App() {
  const _theme = 0; // index in the theme/index.js file

  // for contexts
  const [defaultRunid, setDefaultRunid] = useState(null);
  const [autodispatch, setAutodispatch] = useState(null);
  const [mapping, setMapping] = useState(null);
  const [serverVersion, setServerVersion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [infoHours, setInfoHours] = useState({});
  const [constants, setConstants] = useState({ready: false});
  // var isAvailable = is_available;
  const [isAvailable, setIsAvailable] = useState(true);


  // auth
  const existingAuthToken = JSON.parse(localStorage.getItem('auth-token'));
  const [authToken, setAuthToken] = useState(existingAuthToken);
  
  const existingRefreshToken = localStorage.getItem('refresh-token');
  
  // WIP on reauth
  // eslint-disable-next-line
  const [refreshToken, setRefreshToken] = useState(existingRefreshToken);
  
  const existingUser = JSON.parse(localStorage.getItem('user'));
  const [currentuser, setCurrentuser] = useState(existingUser || {});
  
  const [intervalAuth, setIntervalAuth] = useState(null);
  
  const sentryDialogOptions = {
    // other variables here: 
    // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/user-feedback/#customizing-the-widget
    lang: 'fr' // auto-detected, but I'm doing this to avoid the inevitable 20 minute conversation with the PM about it
  }

  const setTokenForAuth = (data) => {
    if(data === null){
      localStorage.clear('auth-token');
    } else {
      localStorage.setItem('auth-token', JSON.stringify(data));
    }
    setAuthToken(data);
    if(data && currentuser.userid && currentuser.userid !== data.userid)
      rtUsersAccountGet(setCurrentuser)

    if(!intervalAuth && data)
      setIntervalAuth(setInterval(TestAuth, 1000))
  }
  
  const setTokenForRefresh = (data) => {
    if(data === null){
      localStorage.clear('refresh-token');
    } else {
      localStorage.setItem('refresh-token', data);
    }
    setRefreshToken(data);
  }

  const TestAuth = () => {
    const currentStoredToken = JSON.parse(localStorage.getItem('auth-token'))
    if(authToken && !currentStoredToken){
      setTokenForAuth(null);
      SetUser(null);
    }

    // if(currentStoredToken && authToken !== currentStoredToken){
    //   setTokenForAuth(currentStoredToken)
    // }
  }

  useEffect(() => {

    if(authToken !== null && (currentuser.userid !== authToken.userid || !currentuser.userid)){
      // if(!localStorage.getItem('auth-token')){
      //   return;
      // }
      rtUsersAccountGet(setCurrentuser);
    }
    
    if(!intervalAuth){
      // if(!localStorage.getItem('auth-token')){
      //   return;
      // }
      setIntervalAuth(setInterval(TestAuth, 1000))
    }
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // load cache
    setConstants(JSON.parse(localStorage.getItem('constants')) || null);
    LoadConstants((c) => {
      if(c){
        setConstants(c);
        setInfoHours(c.InfoHours);        
      }
    })
  }, [])

  useEffect(() => {

    // TODO refactor
    axios({ 
      headers: GetHeaders(), method: 'GET', url: `${API_ROOT}/system/default/runid` 
    }).then(r => {
      setDefaultRunid(_.get(r, 'data.Default.value', 'default'));
    }).catch(e => {
      console.error('/system/default/runid error')
    });

    axios({ 
      headers: GetHeaders(), method: 'GET', url: `${API_ROOT}/system/default/autodispatch_on` 
    }).then(r => {
      setAutodispatch(_.get(r, 'data.Default.value', false));
    }).catch(e => {
      console.error('/system/default/autodispatch_on error')
    });

    axios({ 
      headers: GetHeaders(), method: 'GET', url: `${API_ROOT}/system/default/mapping_on` 
    }).then(r => {
      setMapping(_.get(r, 'data.Default.value', false));
    }).catch(e => {
      console.error('/system/default/mapping_on error')
    });

    axios({ 
      method: 'GET', url: `${API_ROOT}/version` 
    }).then(r => {
      setServerVersion(_.get(r, 'data.version', '?'));
      console.log(`\nServer Version ${r.data.version}\n`);
    }).catch(e => {
      console.error('get system version error')
    });
  }, []);


  //
  // socket.io (global handler)
  //
  useEffect(() => {
    function socketStart(){
      if(_debug) console.debug('IO socketStart')

      socket = socketIOClient(SOCKETIO_ENDPOINT, {
        transports: ['websocket'],
        // transports: ['polling'],
        secure: true,
        // reconnectionDelay: 1000,
        // reconnectionDelayMax: 10000,
        // transports: ['websocket', 'polling'],
        // reconnect: false,
        // multiplex: false, 
        // autoConnect: true, 
        // forceNew: false, 
        // reconnect: true,
      });

      socketListenerStart();

      socket.on('connect_error', (a) => {
        if(_debug) console.debug('IO connect_error', a)
        setIsAvailable(false);
      });
      socket.io.on('reconnect', (a) => {
        if(_debug) console.debug('IO reconnect', a)
        setIsAvailable(true);
      });
      socket.io.on('reconnection_attempt', (a) => {
        if(_debug) console.debug('IO reconnection_attempt', a)
        setIsAvailable(true);
      });
      socket.io.on('close', (a) => {
        if(_debug) console.debug('IO close', a)
      });
      socket.on('disconnect', reason => {
        if(_debug) console.debug('IO disconnect', reason)
        setIsAvailable(false);
        // if(['forced close', 'transport close', 'io server disconnect', 'io client disconnect'].includes(reason)){
        //   console.log('IO disconnect', '…calling socket.off')
        //   console.log('IO reconnecting…')
          setTimeout(() => {
            // socket.off();
            // socket.disconnect();
            // socket = null;
            // listenerDestroy();
            // socketStart()
          }, 500);
        // }
      });
      socket.on('connect', () => {
        setIsAvailable(true);
        if(_debug) console.debug('IO connect',  socket.id, socket.listeners('ping'), socket.connected)
      });
    }

    function socketStop(){
      if(socket){
        socket.off();
        socket.close();
        socket.disconnect();
        socket.destroy();
      }
    }

    function socketListenerStart(){
      if(!socket)
        return;

      SOCKETS_FOR_EMITTER.forEach(signal => {
        // Socket.off(signal);
        socket.on(signal, data => {
          setIsAvailable(true);
          Emitter.emit(signal, data)
        });
      });

      // ['system_default_runid_change','system_default_autodispatch_on','system_default_mapping_on'].forEach(a => Socket.off(a));

      socket.on('ping', data => {
        if(_debug) console.debug('IO PING', socket.id, data)
      });
      socket.on('system_default_runid_change', data => {
        setIsAvailable(true);
        setDefaultRunid(data.default_runid);
      });
      socket.on('system_default_autodispatch_on', data => {
        setIsAvailable(true);
        setAutodispatch(data.autodispatch_on);
      });
      socket.on('system_default_mapping_on', data => {
        setIsAvailable(true);
        setMapping(data.mapping_on);
      });
      
      // socket.onAny(message => console.log('IO message', message))
     
      if(_debug) console.debug('IO listener setup')
    }

  
    socketStart();
    return () => socketStop()
  }, [])






  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Cambos Warp"
        defaultTitle="Cambos Warp"
      />
        {/*? <Loader />*/}
      { (!constants || constants.ready === false) || (authToken && (!currentuser || !currentuser.userid))
        ? <PageLoading message="" />
        : <StylesProvider injectFirst>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MuiThemeProvider theme={maTheme[_theme]}>
                <ThemeProvider theme={maTheme[_theme]}>
                  <Sentry.ErrorBoundary fallback={"An error has occurred"} showDialog dialogOptions={sentryDialogOptions}>

                {/*<MuiThemeProvider>*/}
                  {/*<ThemeProvider>*/}
                    

                    {/* Globals which update regularly... */}
                    <SnackbarProvider 
                      maxSnack={5}
                      anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'top'
                      }}
                    >
                      <DefaultRunidContext.Provider value={defaultRunid}>
                        <ServerIsAvailableContext.Provider value={{isAvailable/*, setIsAvailable*/}}>
                          <AutodispatchContext.Provider value={{autodispatch, setAutodispatch}}>
                            <MappingContext.Provider value={{mapping, setMapping}}>
                              <ServerVersionContext.Provider value={serverVersion}>
                                <IsLoadingContext.Provider value={{isLoading, setIsLoading}}>
                                  <ConstantsContext.Provider value={{ Constants: constants, setConstants}}>
                                    <OpeningStatusContext.Provider value={{infoHours, setInfoHours}}>
                                      <AuthContext.Provider value={{ authToken, setAuthToken: setTokenForAuth, setRefreshToken: setTokenForRefresh }}>
                                        {/*
                                        <UserContext.Provider value={{ currentuser, setCurrentuser: setUser }}>
                                        */}
                                          
                                          <ApiUiProvider>                  
                                            <Routes /> {/* Main layout entry point */}
                                          </ApiUiProvider>                                          

                                        {/*
                                        </UserContext.Provider>
                                        */}
                                      </AuthContext.Provider>
                                    </OpeningStatusContext.Provider>
                                  </ConstantsContext.Provider>
                                </IsLoadingContext.Provider>
                              </ServerVersionContext.Provider>
                            </MappingContext.Provider>
                          </AutodispatchContext.Provider>
                        </ServerIsAvailableContext.Provider>
                      </DefaultRunidContext.Provider>
                    </SnackbarProvider>

                  </Sentry.ErrorBoundary>
                </ThemeProvider>
              </MuiThemeProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
            // <Page404 />
      }
    </React.Fragment>
  );
}

export default Sentry.withProfiler(App);