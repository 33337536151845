import React from 'react';
import styled from "styled-components";
import { CDN_ROOT } from '../config.js';
import "../pages/CC-Simple.css";

  // border: 3px solid rgba(255,255,255,0.5);
const Avatar = styled.div`
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-position: top;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export function ProductUrl({ size = 'ib', imagekey, ext = '.jpg' }) {
  return [
    CDN_ROOT, '/products/', size.toLowerCase(), '/', imagekey, ext
  ].join('')
}
export function ProductImage({ imagekey, size = 'ib', w = '50px', h = '50px' }) {
  // let url = [ CDN_ROOT, '/products/', size.toLowerCase(), '/', imagekey, '.jpg'].join('')
  let url = ProductUrl({ size, imagekey, ext: '.jpg' })
  let fallbackUrl = ProductUrl({ size, imagekey: 'fallback', ext: '.png' })

  return (
    <img
      alt="article"
      src={url}
      onError={(e)=>{
        e.target.onerror = null;
        e.target.src = fallbackUrl;
      }}
      style={{maxWidth: w, maxHeight: h}}
      />
  )
}
export function CarUrl({ size = 'ib', imagekey, ext = '.jpg' }) {
  return [
    CDN_ROOT, '/cars/', imagekey, ext
  ].join('')
}
export function CarImage({ imagekey, size = 'ib', w = '50px', h = '50px' }) {
  // let url = [ CDN_ROOT, '/products/', size.toLowerCase(), '/', imagekey, '.jpg'].join('')
  let url = CarUrl({ size, imagekey, ext: '.jpg' })
  let fallbackUrl = ProductUrl({ size, imagekey: 'fallback', ext: '.png' })

  return (
    <img 
      alt="vehicule"
      src={url} 
      onError={(e)=>{
        e.target.onerror = null; 
        e.target.src = fallbackUrl;
      }}
      style={{maxWidth: w, maxHeight: h}}
      />
  )
}

export function UserUrl({ size = 'ib', imagekey, ext = '.jpg' }) {
  return [
    CDN_ROOT, '/users/', imagekey, ext
  ].join('')
}
export function UserImage({ imagekey, size = 'ib', w = '50px', h = '50px' }) {
  // let url = [ CDN_ROOT, '/products/', size.toLowerCase(), '/', imagekey, '.jpg'].join('')
  let url = UserUrl({ size, imagekey, ext: '.jpg' })
  let fallbackUrl = ProductUrl({ size, imagekey: 'fallback', ext: '.png' })

  return (
    <img 
      alt="user"
      src={url} 
      onError={(e)=>{
        e.target.onerror = null; 
        e.target.src = fallbackUrl;
      }}
      style={{maxWidth: w, maxHeight: h}}
      />
  )
}

export function UserAvatarImage({ imagekey }){
  return (
    <Avatar className="avatar" imageUrl={UserUrl({ imagekey, ext: '.jpg' })} />
  )
}


export function SupplierUrl({ size = 'ib', imagekey, ext = '.jpg' }) {
  return [
    CDN_ROOT, '/suppliers/', imagekey, ext
  ].join('')
}
export function SupplierImage({ imagekey, size = 'ib', w = '50px', h = '50px' }) {
  // let url = [ CDN_ROOT, '/products/', size.toLowerCase(), '/', imagekey, '.jpg'].join('')
  let url = UserUrl({ size, imagekey, ext: '.jpg' })
  let fallbackUrl = ProductUrl({ size, imagekey: 'fallback', ext: '.png' })

  return (
    <img 
      alt="founisseur"
      src={url} 
      onError={(e)=>{
        e.target.onerror = null; 
        e.target.src = fallbackUrl;
      }}
      style={{maxWidth: w, maxHeight: h}}
      />
  )
}
