import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { dashboard as dashboardRoutes, auth as authRoutes } from './index';

import PrivateRoute from './PrivateRoute';
import DashboardLayout from '../layouts/Dashboard';
import AuthLayout from '../layouts/Auth';
import Page404 from '../pages/auth/Page404';

const privateChildRoutes = (Layout, routes) =>
  routes.map(({ onEnter, disabled, children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        //<Route
        <PrivateRoute
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      //<Route
      <PrivateRoute
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

const publicChildRoutes = (Layout, routes) =>
  routes.map(({ onEnter, disabled, children, path, component: Component }, index) =>
    children ? (
      // Route item with children
        // <Route
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <PrivateRoute
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      {privateChildRoutes(DashboardLayout, dashboardRoutes)}
      {publicChildRoutes(AuthLayout, authRoutes)}
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);


export default Routes;
