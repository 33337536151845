import React from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";

import { useSnackbar } from 'notistack'; // SnackbarProvider: global snackbar provider
import { API_ROOT, ApiCallContext, IsLoadingContext, GetHeaders } from "../config.js";

// export const ApiCallContext = React.createContext()
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// import { useAuth } from "../Auth.js";

import { UnfoldLess, UnfoldMore } from "@material-ui/icons";

// const _debug = false;
const _debug = true;
const AUTH_EXPIRED = 'not_auth no session or deleted';
const MAX_RETRIES = 10;

export const MakeApiCall = function({
// export const MakeApiCall = ({
  url, headers, method, data, 
  success, error, // functions as callbacks or strings for toast
  error_message,
  fin,
  use_auth, 
  show_modal_on_error,
  log_label, // for logging
  retries, 
  maxRedirects,
  showModal, showToast, setIsLoading, userAuth
  // toast_success, toast_error
  }) {
  // }) => {

  let _headers = GetHeaders();
  if(headers)
    Object.keys(headers).forEach((h) => { _headers[h] = headers[h] })

  let axios_options = {
    url: `${API_ROOT}${url}`,
    headers: _headers,
    method: method || 'GET',
    maxRedirects: maxRedirects,
  }

  // if(maxRedirects)
    // axios_options.maxRedirects = maxRedirects;
  
  if(!(url.includes('png') || url.includes('jpg')))
    axios_options.responseType = 'json';

  if(data)
    axios_options.data = data;

  // const _call = (retry) => {
  function _call(retry){
    // if(axios_options.url.includes('stocktemplates')){
    //   console.log('foo')
    // }
    if(retry > MAX_RETRIES){
      return redirectToTimeout()
    }

    // const interceptorSuccess = (response) => response;
    // const interceptorError = (error) => {
    //   if (error && error.response.status === 401 && error.response.data.message.includes(AUTH_EXPIRED)) {
    //     debugger;
    //     console.log('_call interceptor 1 401')
    //     return errorHandler(error);
    //   } else {
    //     return error;
    //   }
    // }

    // const successHandler = (r) => {
    function successHandler(r){

      if(_debug) console.debug('ApiCall axios request OK', log_label, r);
      if(typeof success === 'function'){
        success(r);
      } else if (typeof success === 'string'){
        showToast(success, 'info')
      }
      setIsLoading(false); 
    }

    // const errorHandler = (e) => {
    function errorHandler(e){
      // if (e && e.response && e.response.status === 401 && e.response.data.message.includes(AUTH_EXPIRED)) {
      //   return Reauth(retry)
      // }
      // console.log('_call errorHandler')

      if (e && e.response && e.response.status === 401
       && e.response.data.message.includes(AUTH_EXPIRED)
       ) {
        // debugger;
        console.log('_call interceptor 1 401')
        return redirectToTimeout();
        // return errorHandler(error);
      // } else {
        // return error;
      }

      let k = {
        axios_options: axios_options, 
        response: ['downstream error', e]
      };
      if(e && e.response){
        k.response = {
          data: e.response.data,
          headers: e.response.headers,
          status: e.response.status,
          statusText: e.response.statusText
        }
      } else {
        if(_debug) console.error('no e.response', e);
      }

      stripSensitive(k);

      if(_debug) console.debug(`ApiCall axios request error ${log_label}`, e);
      if(log_label === 'rtUsersAuthSendOtpPost'){
        console.log('rtUsersAuthSendOtpPost error')
        // debugger;
      }

      if(typeof error === 'function'){
        error(k);
      } else if (typeof error === 'string'){
        showToast(error, 'error')
      } 

      setIsLoading(false);

      if(show_modal_on_error){
        const server_message = (e && e.response && e.response.data && e.response.data ? e.response.data.user_message : null);
        // setTimeout(() => showModal('Erreur', show_modal_on_error, server_message, k), 0);
        setTimeout(function(){showModal('Message de la plate-forme', show_modal_on_error, server_message, k)}, 0);
      }
    }


    // axios.interceptors.response.use(response => response, error => errorHandler(error));


    //
    // The main API call is triggered here
    //

    if(_debug) console.debug('ApiCall.js calling axios with options', axios_options)
    axios(axios_options)
    .then(successHandler)
    .catch(errorHandler)    
    // .finally(() => {
    //   if(typeof fin === 'function'){ fin() }
    // });
    .finally(function(){
      if(typeof fin === 'function'){ fin() }
    });


    // function interceptorError(error){
    //   if (error && error.response.status === 401){// && error.response.data.message.includes(AUTH_EXPIRED)) {
    //     debugger;
    //     console.log('_call interceptor 2 401')
    //     return redirectToTimeout();
    //     // TODO this needs a whole lot of work
    //     // Reauth(function(e){
    //     //   if(e){
    //     //     if(_debug) console.log('Reauth response', e);
    //     //     return redirectToTimeout();
    //     //   } else {
    //     //     retry ++;
    //     //     setTimeout(() => _call(retry), 0);
    //     //   }
    //     // })
    //   } else {
    //     return error;
    //   }
    // }

  }

  if(_debug) console.debug('ApiCall', { axios_options, use_auth, show_modal_on_error });
  setTimeout(function(){
    setIsLoading(true)
    _call(0);
  }, 0);
  // setTimeout(() => { _call() }, 5000);
}


export const ApiUiProvider = ({ children }) => {

  const { enqueueSnackbar } = useSnackbar();
  const { setIsLoading } = React.useContext(IsLoadingContext);
  const history = useHistory();

  // error dialog
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorJson, setErrorJson] = React.useState({});
  const [showJson, setShowJson] = React.useState(false);
  const [dialogContentText, setDialogContentText] = React.useState('');
  const [dialogContentTextServer, setDialogContentTextServer] = React.useState('');

  const handleButtonHome = () => {
    setErrorOpen(false);
    if(history)
      history.push(`/dashboard`)
    else
      window.location.href = `/dashboard`;
  };
  const handleButtonReload = () => {
    setErrorOpen(false);
    window.location.reload(false);
  };
  const handleClose = () => {
    setErrorOpen(false);
  };

  const ApiCall = ({
    url, headers, method, data, 
    success, error, // functions as callbacks or strings for toast
    fin, use_auth, show_modal_on_error, log_label, retries, maxRedirects
  }) => {
    const showToast = (message, variant) => {
      enqueueSnackbar(message, { variant });
    }
    const showModal = (title, message, server_message, json) => {
      setErrorOpen(true);
      setDialogContentText(message || 'Une erreur importante est survenue');
      if(server_message)
        setDialogContentTextServer(server_message);
      else
        setDialogContentTextServer(null);

      if(json){
        setErrorJson(json);
      } else {
        setErrorJson(null);
      }
    }

    const userAuth = () => {
      return {}
    }

    MakeApiCall({
      url, headers, method, data, 
      success, error, // functions as callbacks or strings for toast
      fin, 
      use_auth, 
      show_modal_on_error,
      log_label, // for logging
      retries, 
      showModal, 
      showToast, 
      userAuth,
      maxRedirects,
      setIsLoading: setIsLoading
    });
  }

  return(
    <ApiCallContext.Provider value={ApiCall}>
      {/* ERROR DIALOG */}
      <Dialog
        open={errorOpen}
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll="paper"
        >
        <DialogTitle id="alert-dialog-title">Message de la plate-forme</DialogTitle>
        <DialogContent>
          <DialogContentText style={{fontSize: 16, fontWeight: 'bold'}} id="alert-dialog-description">{dialogContentText}</DialogContentText>
          <DialogContentText style={{fontSize: 16, fontWeight: 'bold'}} id="alert-dialog-description-server">{dialogContentTextServer}</DialogContentText>
          
          { errorJson && 
            <React.Fragment>
              <Button style={{opacity: 0.5}} onClick={() => setShowJson(!showJson)} color="default" endIcon={showJson?<UnfoldLess/>:<UnfoldMore/>}>Afficher détails techniques</Button>
              { showJson && 
                <pre>{JSON.stringify(errorJson, null, 2)}</pre>
              }
            </React.Fragment>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">Fermer</Button>
          <Button onClick={handleButtonHome} color="default">Retour Accueil</Button>
          <Button onClick={handleButtonReload} color="primary" autoFocus variant="contained">Recharger Page</Button>
        </DialogActions>
      </Dialog>

      {/* Rest of site content */}
      {children}
    </ApiCallContext.Provider>
  )
}



// WIP for retrying an authorization
// eslint-disable-next-line
const Reauth = (cb) => {
  var refreshtoken = localStorage.getItem('refresh-token');
  var bearertoken = null;
  
  var authJson = localStorage.getItem('auth-token');
  var _url = null;

  let _headers = GetHeaders();
  
  if(!refreshtoken || refreshtoken.length < 10 || !authJson || authJson.length < 10){
    return redirectToTimeout();
  }
  try{ bearertoken = JSON.parse(authJson).access_token; } catch(a){ return redirectToTimeout(); }

  // axios.interceptors.response.use(response => {
  //    return response;
  // }, error => {
  //   // debugger;
  //   if(error && error.response && error.response.status === 401){// && error.response.data.message.includes(AUTH_EXPIRED)){
  //     console.log('Reauth interceptor 401');
  //     return redirectToTimeout();
  //   }
  //   return error;
  // });
  _url = `${API_ROOT}/users/auth/renew?bearertokenid=${encodeURIComponent(bearertoken)}&refreshtoken=${encodeURIComponent(refreshtoken)}`;
  console.log('Reauth calling renew', _url);

  axios({
    url: _url,
    headers: { ApiKey: _headers.ApiKey, 'Content-Type': _headers['Content-Type'] },
    method: 'POST'
  })
  .then((r) => {
    console.log('ApiCall call renew call', r);
    if(r && r.data && r.data.access_token){
      if(_debug) console.debug('ApiCall renew token request OK; retrying failed call', r.data);
    // set new token
      localStorage.setItem('auth-token', JSON.stringify(r.data));
      cb(null);
    } else {
      cb('ApiCall can\'t renew token');
    }
  })
  .catch((e) => {
    console.log('ApiCall can\'t call renew call', e);
    cb('ApiCall can\'t call renew call');
  }); 
}




const redirectToTimeout = () => {
  console.log('redirectToTimeout');
  var _loc = window.location.href;
  // debugger;
  window.location.href = `/auth/expired?redirect=${_loc}`;
}


//
// on mpd-api-main too
//
const stripSensitive = (j) => {

  Object.keys(j).forEach((key) => {
    if(key === 'password'){
      j[key] = '- password removed -'

    } else if(key === 'bearer_token'){
      j[key] = '- bearer_token removed -'

    } else if(key === 'refresh_token'){
      j[key] = '- refresh_token removed -'

    } else if(Object.prototype.toString.call( j[key] ) === '[object Object]'){
      stripSensitive(j[key])

    } else if(Object.prototype.toString.call( j[key] ) === '[object Array]'){
      j[key].forEach((subkey) => {
        if(Object.prototype.toString.call( j[key][subkey] ) === '[object Object]')
          stripSensitive(j[key][subkey])
        if(subkey === 'otp')
          j[key][subkey] = '- otp removed -'
        if(subkey === 'password')
          j[key][subkey] = '- password removed -'
        if(subkey === 'bearer_token')
          j[key][subkey] = '- bearer_token removed -'
        if(subkey === 'refresh_token')
          j[key][subkey] = '- refresh_token removed -'
      })

    }
  })
}
