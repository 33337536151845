import { createContext, useContext } from 'react';

//
// Auth
//

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}


//
// User
// 

export const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export const GetUser = () => {
  const data = JSON.parse(localStorage.getItem('user')) || {};
  return data;
}

export const SetUser = (data) => {
  if(data === null)
    localStorage.clear('user');
  else
    localStorage.setItem('user', JSON.stringify(data));
}
