import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { format } from 'date-fns-tz'
import { fr } from 'date-fns/locale'
import { 
  DefaultRunidContext,
  ServerVersionContext,
  OpeningStatusContext,
  ServerIsAvailableContext,
  AutodispatchContext,
  FriendlyRunid
} from '../config.js';
import { useHistory } from "react-router-dom";

import "../pages/CC-Simple.css";

import {
  Grid,
  Hidden,
  List,
  ListItemText,
  ListItemIcon,
  ListItem as MuiListItem
} from "@material-ui/core";

import {
  Activity, Play, Pause, Square,
  // Cloud, 
  Clock as ClockIcon,
  Calendar
} from "react-feather";

import {   
  Menu as MenuIcon,
  Timer as TimerIcon,
  TimerOff as TimerOffIcon 
} from "@material-ui/icons";

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: ${props => props.theme.spacing(1) / 4}px
    ${props => props.theme.spacing(4)}px;
  background: ${props => props.theme.palette.common.white};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${props => props.theme.spacing(2)}px;
  padding-right: ${props => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;

const iconStyle = {
  maxWidth: 18, 
  position: 'relative', 
  top: 7, 
  marginRight: 5, 
  opacity: 0.5
}

function ServerStatus({ up }){
  return(
    <div style={{marginRight: 12}}>
      <Activity style={iconStyle}/>
      <span style={{opacity: 0.8}}>Connexion </span>
      { up 
        ? <span style={{opacity: 0.8}}>OK</span>
        : <span role="img" aria-label="not connected">🔴</span>
      }
    </div>
  )
}
function AutodispatchStatus({ on }){
  return(
    <div style={{marginRight: 12}}>
      { on 
        ? <TimerIcon style={iconStyle}/>
        : <TimerOffIcon style={iconStyle}/>
      }
      <span style={{opacity: 0.8}}>Autodispatch </span>
      { on 
        ? <span style={{opacity: 0.8}}>activé</span>
        : <span style={{opacity: 0.8}}>arrêté</span>
      }
    </div>
  )
}


function OpeningStatus({ infoHours }){
  return(
    <div style={{marginRight: 12, opacity: 0.8}}>
      {infoHours.opening_status === 'paused' && 
        <span>
          <Pause style={iconStyle} />
          Commandes en Pause
        </span> }
      {infoHours.opening_status === 'open' && 
        <span>
          <Play style={iconStyle} />
          Commandes Acceptées
        </span> }
      {infoHours.opening_status === 'closed' && 
        <span>
          <Square style={iconStyle} />
          Commandes Refusées
        </span> }
    </div>
  )
}

function Clock() {
  const [time, setTime] = useState('');

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      setTime(format(new Date(), 'HH:mm:ss', {locale: fr}));
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  return (
    <React.Fragment>
    { time && 
      <div className="cc Clock">
        <ClockIcon style={iconStyle}/>
        <span className="">{time.split(':')[0]}</span>
        <span className="thinner">:</span>
        <span className="">{time.split(':')[1]}</span>
        <span className="thinner">:</span>
        <span className="thinner">{time.split(':')[2]}</span>
      </div>
    }
    </React.Fragment>
  )
}

function Footer({ onDrawerToggle }) {
  const defaultRunid = useContext(DefaultRunidContext);
  const serverVersion = useContext(ServerVersionContext);
  const { isAvailable } = useContext(ServerIsAvailableContext);
  const { autodispatch } = useContext(AutodispatchContext);
  const { infoHours } = useContext(OpeningStatusContext);
  const history = useHistory();

  const openRuns = () => {
    history.push('/runs')
  }

  return (
    <Wrapper>
      <Grid container spacing={0} justify="space-between" alignItems="center">
        <List>
          <Hidden smDown>
            <ListItem>
              <ListItemText primary={ <Clock /> } />
            </ListItem>
          </Hidden>
            {/*
          */}

          <Hidden smDown>
            <Calendar style={iconStyle}/>
            <span style={{opacity: 0.8}}>Journée Active</span>
            <ListItem onClick={openRuns} style={{cursor: 'pointer'}}>
              <ListItemText primary={
                defaultRunid 
                ? FriendlyRunid(defaultRunid)
                : 'aucune'
              } />
            </ListItem>
          </Hidden>

          <Hidden smDown>
            <ListItem>
              <ListItemText primary={ <AutodispatchStatus on={autodispatch} /> } />
            </ListItem>
          </Hidden>

          <Hidden smDown>
            <ListItem>
              <ListItemText primary={ <ServerStatus up={isAvailable} /> } />
            </ListItem>
          </Hidden>

          <Hidden smDown>
            <ListItem>
              <ListItemText primary={ <OpeningStatus infoHours={infoHours} /> } />
            </ListItem>
          </Hidden>

          <Hidden smUp>
            <ListItem button onClick={onDrawerToggle}>
              <ListItemIcon><MenuIcon /></ListItemIcon>
            </ListItem>
          </Hidden>
        </List>

        <List>
          <ListItem>
            <ListItemText 
              style={{opacity: 0.8}} 
              primary={`Admin v${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_BRANCH}) ${process.env.REACT_APP_TAG} · API v${serverVersion||'??'} · Powered by Cambos Warp`} 
              />
          </ListItem>
        </List>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
