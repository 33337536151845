// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

//
// sentry.io
//
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from '@sentry/integrations';

// killing off redux stuff
// import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
// import { ThemeProvider } from "styled-components";
// import maTheme from "./theme";
// import { Provider } from 'react-redux';
// import store from './redux/store/index';

console.log(`\nStarting mpd-ui-admin version ${process.env.REACT_APP_VERSION}@${process.env.REACT_APP_GIT} Déploiement ${process.env.REACT_APP_TAG} Branche ${process.env.REACT_APP_BRANCH}\n`);

Sentry.init({
  dsn: "https://4fc3e79c49fd42c2b18ecf41b0c54bf2@o495575.ingest.sentry.io/5568512",
  environment: process.env.REACT_APP_BRANCH || 'unknown',
  release: `mpd-ui-admin@${process.env.REACT_APP_VERSION}-B-${process.env.REACT_APP_BRANCH}#${process.env.REACT_APP_GIT}`,
  // release: process.env.REACT_APP_SENTRY_RELEASE,

  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsole({
      levels: ['error']
    })
  ],

  autoSessionTracking: true,
  maxBreadcrumbs: 50, //?

  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],

  // We recommend adjusting this value in production, or using tracesSampler for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(  
  // <Provider store={store}>  // sillyness for redux
    <App />
  // </Provider>
  , document.getElementById('root')
);
