import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../Auth.js";

export default function PrivateRoute ({ 
  key, path, exact, render
}) {
  const { authToken } = useAuth();
  // console.log('PrivateRoute', authToken ? 'auth token present' : 'no auth token')
  return (
    authToken 
    ?
    <Route
      key={key} path={path} exact={exact}
      render={render} 
    />
    : <Redirect to="/auth/sign-in"/> 
  )
}
