import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IsLoadingContext, ServerIsAvailableContext } from '../config.js';
import '../pages/CC-Simple.css';

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles({
//   bar1Indeterminate: {
//     background: 'linear-gradient(0deg, #660000 30%, #AA0000 90%)'
//   }
// })

const Header = ({ onDrawerToggle }) => {
  const { isLoading } = React.useContext(IsLoadingContext)
  const { isAvailable } = React.useContext(ServerIsAvailableContext);
  // const classes = useStyles();
  return (
    <React.Fragment>
      <div className="fixed-progress-bar">
        { isLoading && 
          <LinearProgress color="secondary" variant="indeterminate" />
        }
        { isAvailable === false && 
          <div className='red-bar'></div> 
        }
      </div>
    </React.Fragment>
  );
}

export default Header;
