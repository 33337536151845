// this file is indented to allow collapsing under comments...

// Imports
  import React from "react";
  import axios from 'axios';
  import format2Decimals from './components/format2Decimals.js'
  import format2Percent from './components/format2Percent.js'
  import { format } from 'date-fns-tz'
  import { fr } from 'date-fns/locale'
  import _ from 'lodash';
  
const _debug = false;
// const _debug = true;

// variables used in this file
  const BRANDID = 'alloapero';
  let backendHost;
  let backendKey;
  let socketioHost;
  let cdnHost;
  let _preloadBrandname; // used while constants cannot be loaded (no-auth)
  let _ServerConstants = {
    brandid: BRANDID
  }

  const hostname = window && window.location && window.location.hostname;

// settings based on domain name
  console.log('hostname', hostname)
  switch(hostname){
    case 'alloaperoparis-demo.warp.cambos.net':
      backendKey = '9a513523f38397f9a037d8cd633dbbbe-warp-api';
      backendHost = `https://api-${hostname}`;
      // backendHost = `https://api-${hostname}`;
      socketioHost = `https://api-${hostname}`; // nginx must map the /socket.io appended part to the server; this means effectively two routes to same server resource on the nginx config, one for /main, one for /socket.io
      cdnHost = 'https://warp-demo.ams3.digitaloceanspaces.com';
      // cdnHost = 'https://cdn.paris-drink.net';
      _preloadBrandname = 'Cambos Warp (Demo)';
      break;
    case 'alloaperoparis-dev.warp.cambos.net':
      backendKey = '9a513523f38397f9a037d8cd633dbbbe-warp-api';
      backendHost = `https://api-${hostname}`;
      socketioHost = `https://api-${hostname}`; // nginx must map the /socket.io appended part to the server; this means effectively two routes to same server resource on the nginx config, one for /main, one for /socket.io
      cdnHost = 'https://warp-dev.ams3.digitaloceanspaces.com';
      // cdnHost = 'https://cdn.paris-drink.net';
      _preloadBrandname = 'Cambos Warp (Dev)';
      break;
    case 'alloaperoparis-staging.warp.cambos.net': 
      backendKey = '9a513523f38397f9a037d8cd633dbbbe-warp-api';
      backendHost = `https://api-${hostname}`;
      socketioHost = `https://api-${hostname}`;
      cdnHost = 'https://warp.ams3.digitaloceanspaces.com';
      // cdnHost = 'https://cdn.paris-drink.net';
      _preloadBrandname = 'Cambos Warp (Staging)';
      break;
    case 'alloaperoparis.warp.cambos.net': 
      backendKey = '9a513523f38397f9a037d8cd633dbbbe-warp-api';
      backendHost = `https://api-${hostname}`;
      socketioHost = `https://api-${hostname}`;
      cdnHost = 'https://warp.ams3.digitaloceanspaces.com';
      // cdnHost = 'https://cdn.paris-drink.net';
      _preloadBrandname = 'Cambos Warp';
      break;
    case 'dev-kos.paris-drink.net':
    default: 
      // for dev
      backendKey = '9a513523f38397f9a037d8cd633dbbbe-warp-api';
      backendHost = 'http://'+hostname+':13100';
      socketioHost = backendHost;
      cdnHost = 'https://warp-dev.ams3.digitaloceanspaces.com';
      // cdnHost = 'https://cdn.paris-drink.net';
      _preloadBrandname = 'Cambos Warp (Dev)';
      break;
  }

  console.debug (`For hostname ${hostname} applying API_ROOT ${backendHost} SOCKETIO_ENDPOINT ${socketioHost} and CDN_ROOT ${cdnHost}`);

// global constants
  export const PreloadBrandname = _preloadBrandname;

  export const API_ROOT = `${backendHost}`;
  export const CDN_PATH = `alloaperoparis`;
  export const CDN_ROOT = `${cdnHost}/${CDN_PATH}`;
  export const SOCKETIO_ENDPOINT = `${socketioHost}`;
  export const CHANGETO_MAX_UNIT_MULTIPLE = 3; // for substitutions 

  export const API_KEY = `${backendKey}`;

  export const TIMEZONE = 'Europe/Paris';
  export const GOOGLEMAPSKEY = 'AIzaSyBvpRK6gqN7oR901dgyTobH75t6xsJ7yOo';

  export const DISPLAY_MINS_HIGH = 45;
  export const DISPLAY_MINS_LOW = 30;

// Contexts
  //
  // TODO move this outside of config.js; shared React context hooks for system-wide status
  //
  export const IsLoadingContext = React.createContext()
  export const DefaultRunidContext = React.createContext()
  export const ServerVersionContext = React.createContext()
  export const ConstantsContext = React.createContext()
  export const ApiCallContext = React.createContext()
  export const ServerIsAvailableContext = React.createContext()
  export const AutodispatchContext = React.createContext()
  export const MappingContext = React.createContext()
  export const OpeningStatusContext = React.createContext()

// Global functions
  /*
    Handy network mapping functions

    is_available: true
    productname: "Absolut T Vodka 40° (70cl)"
    sale_cost_unit_lesstax: 15.091666666666667
    sale_cost_unit: 18.11 // sale price is TTC; cost price is HT
    sale_taxid: "TVA1001"
  */
  export const PriceFor = (product, networkid, tax) => {
    if(tax){
      if(networkid && product.menus && product.menus[networkid] && product.menus[networkid].sale_cost_unit){
        return product.menus[networkid].sale_cost_unit
      } else {
        return product.sale_cost_unit
      }
    } else {
      if(networkid && product.menus && product.menus[networkid].sale_cost_unit_lesstax){
        return product.menus[networkid].sale_cost_unit_lesstax
      } else {
        return product.sale_cost_unit_lesstax
      }    
    }
  }

  export const NameFor = (product, networkid) => {
    if(networkid && product.menus && product.menus[networkid] && product.menus[networkid].productname){
      return product.menus[networkid].productname
    } else {
      return product.productname
    }
  }

  export const IdFor = (product, networkid) => {
    var ids = product.productid.split('-')[0];
    if(networkid && product.menus && product.menus[networkid] && product.menus[networkid].network_productid){
      ids = `${ids} (réf ${GetConstantsLabel('Network', networkid)} ${product.menus[networkid].network_productid})`;
    }

    return ids
  }

  export const IsExternalNetwork = (networkid) => {
    if(!_ServerConstants || Object.keys(_ServerConstants.Networks).length < 1){
      return null // falsy
    }

    let j = null;
    _ServerConstants.Networks.forEach((a) => {
      if(a.networkid === networkid)
        j = a;
    })

    if(!j)
      return null;
    // uses_proxy_clients is what determines how we behave in the ui
    // is_internal is for stats/reference
    return (j.uses_proxy_clients === true);
  }

  export const GetRegionFromPostcode = (post_code) => {

    // TODO return null if this isn't france; A problem for another day...
    if(!post_code){
      return '--';
    }

    let _post_code = (post_code + '');

    if(_post_code.slice(0,2) === '75')
      return _post_code;
    else
      return _post_code.slice(0,2);

    // if(typeof post_code === 'number'){
    //   return (post_code + '').slice(0,2);

    // } else if(typeof post_code === 'string'){
    //   return post_code.slice(0,2);

    // } else {
    //   return '--';
    // }
  }

  export const TableItemLookup = ({items, matchTo, key1, key2, lookupkey}) => {
    let matched = null;
    const len = items.length;
    let i = 0;

    while(!matched && i < len){
      if(items[i][key1] === matchTo){
        matched = items[i][key2]
      }
      i++
    }

    if(matched && lookupkey)
      matched = GetConstantsLabel(lookupkey, matched);

    return matched ? <span>{matched}</span> : null;
  }

  export const ApiPromise = ({entity, params, method, setIsLoading}) => {
    return fetch(`${API_ROOT}/search/${entity}${params}`, {
        method: method || 'GET',
        headers: GetHeaders()
      })
      .then(response => response.json())
      .then(data => {
        if(data) console.log('ApiPromise: All data', data)
        // if(data.esdebug_e) console.log('ES Debug Err', data.esdebug_e)
        // if(data.esdebug_r) console.log('ES Debug Res', data.esdebug_r)
        // if(data.esdebug_q) console.log('Query', data.esdebug_q)
        // if(data.esdebug_q) console.log('ES Query', JSON.parse(_.get(data.esdebug_r || data.esdebug_e, 'meta.request.params.body', "{}")))
        if(data.esdebug_q) console.log('ApiPromise: ES Query', _.get(data.esdebug_r || data.esdebug_e, 'meta.request.params.body', "{}"))
        // setIsLoading(false);
        if(setIsLoading){ 
          setIsLoading(false)
        }
        console.log('ApiPromise done')
        return {
          data: data.data,
          totalCount: data.totalCount,
          summary: data.summary,
          groupCount: data.groupCount
        };
      })
      .catch(e => { 
        console.log('ApiPromise done (error)')
        if(setIsLoading){ 
          setIsLoading(false)
        }
        // setIsLoading(false);
        throw new Error('Erreur chargement données'); 
      }
    );
  }

  export const GetHeaders = () => {
    let h = { 
      'Content-Type': 'application/json', 
      'ApiKey': backendKey 
    }
    const j = JSON.parse(localStorage.getItem('auth-token'));
    if(j)
      h.Authorization = 'Bearer ' + j.access_token;

    return h;
  }

  export const MuiDatatableLabels = {
    body: {
      noMatch: "Aucun enregistrement",
      toolTip: "Trier",
      columnHeaderTooltip: column => `Trier sur ${column.label}`
    },
    pagination: {
      next: "Page Suivante",
      previous: "Page Précedente",
      rowsPerPage: "par page :",
      displayRows: "sur",
    },
    toolbar: {
      search: "Rechercher",
      downloadCsv: "Télécharger CSV",
      print: "Imprimer",
      viewColumns: "Afficher/Masquer Colonnes",
      filterTable: "Filtrer",
    },
    filter: {
      all: "Tous",
      title: "Filtrer",
      reset: "Effacer les filtres",
    },
    viewColumns: {
      title: "Afficher Colonnes",
      titleAria: "Show/Hide Table Columns",
    },
    selectedRows: {
      text: "Lignes sélectionnées",
      delete: "Supprimer",
      deleteAria: "Delete Selected Rows",
    }
  }



  // this will be a parameter some day ...
  export const formatCurrency = (item) => `${(format2Decimals(item)||'0').replace('.',',')} €`; // nbsp before €

  export const formatPercent = (item) => `${(format2Percent(item)||'').replace('.',',')} %`; // nbsp before %

  export const GetStatusLabel = (id) => {
    if(!_ServerConstants || !_ServerConstants.TourStatus || !_ServerConstants.TourStatus['TourStatus:'+id]){
      console.log(`can't run GetStatusLabel for TourStatus`, id);
      return '(' + id + ')'
    }
    return _ServerConstants.TourStatus['TourStatus:'+id].label_short || 'Unknown';
  }

  export const FriendlyRunid = (runid) => {
    if(!runid || runid.length <10)
      return '- no runid -'
    if(runid.includes('template'))
      return 'template ' + runid.split('.')[3]
    
    return `${format(new Date(runid.split('.').slice(2,3)), 'dd MMM', {locale: fr})} #${runid.split('.').slice(3,4)}`
  }

  export const GetPermissions = () => {
    return _ServerConstants.Permission || [];
  }

  export const GetConstantsLabel = (category, code, alt_text) => {
    const key = category+':'+code;
    if(!_ServerConstants || !_ServerConstants[category] || !_ServerConstants[category][key]){
      // console.log("not found", key, _ServerConstants)
      return alt_text || `(${key})`
    }
    return _ServerConstants[category][key].label_short || _ServerConstants[category][key].label || _ServerConstants[category][key].code || 'Unknown';
  }

  export const GetUserRole = () => {
    // note that actual rules for edits and data visibility are managed by the API
    // and 'admin' just means that we show different stuff...

    const data = JSON.parse(localStorage.getItem('user')) || {};
    return data['usertype'] || 'driver'; // TODO kev => enforce error handling

    // // TODO implement this from the auth token returned by server...
    // return 'bigmanager'
  }

  export const GetConstantsValue = (category, code) => {
    const key = category+':'+code;
    if(!_ServerConstants || !_ServerConstants[category] || !_ServerConstants[category][key])
      return '(' + key + ')'
    return _ServerConstants[category][key].value || 'Unknown';
  }

// Load Constants - UISettings, globals, etc. via server
  // this is called from App.js
  function parseConstantsFromApiResponse(api_response, cbConstants){
    // used by LoadConstants
    const r = api_response;

    if(!(r.data && r.data.InfoSettingsCategories_list)){
      return cbConstants(null)
    }
    let defaults = {};
    if(r && r.data && r.data.Default_list){
      r.data.Default_list.forEach(a => defaults[a.key] = a.value );
    }

    _ServerConstants.InfoSettingsCategories_list = r.data.InfoSettingsCategories_list;

    r.data.InfoSettings_list.forEach(i => {
      if(!_ServerConstants[i.category])
        _ServerConstants[i.category] = {}

      _ServerConstants[i.category][i.category+':'+i.code] = i;
    });
    
    // networks in standard category/code 'constants' format
    _ServerConstants.Network = {}
    r.data.Network_list.forEach(i => _ServerConstants.Network['Network:'+i.code] = i );
    
    // driver names in cached format
    _ServerConstants.DriverName = {}
    if(r.data.Driver_list){
      r.data.Driver_list.forEach(i => _ServerConstants.DriverName['DriverName:'+i.code] = i );
    }
    
    // networks in cached format for use in product, menu, opening times and other places
    _ServerConstants.Networks = r.data.Networks;

    // suppliers in 'constants' format
    _ServerConstants.Supplier = {}
    r.data.Supplier_list.forEach(i => _ServerConstants.Supplier['Supplier:'+i.code] = i );
    
    // Messages (anomalie, notification, retard, ordre special)
    _ServerConstants.MessageTemplate = {}
    r.data.MessageTemplate_list.forEach(i => _ServerConstants.MessageTemplate[i.messagetemplateid] = i );

    _ServerConstants.Permission = r.data.Permission_list;
    _ServerConstants.PermissionBlockScreen = {}
    const user_role = GetUserRole();
    _ServerConstants.Permission.filter(a => a.type === 'screen').forEach(p => {
      if(p[user_role] && p[user_role].includes('hide')){
        _ServerConstants.PermissionBlockScreen[p.ref] = true;
      } 
    })

    // _ServerConstants.Permission = {}
    // r.data.Permission_list.forEach(function(i){ _ServerConstants.Permission[i.permissionid] = i; });

    // suppliers in cached format; not currently used
    _ServerConstants.Suppliers = r.data.Suppliers;
    
    _ServerConstants.Productcombo_list = r.data.Productcombo_list;

    _ServerConstants.brandname = defaults.brandname;
      
    _ServerConstants.use_ibar_for_purchasing = defaults.use_ibar_for_purchasing;

    _ServerConstants.default_taxid = defaults.taxid;
    _ServerConstants.default_runid = defaults.default_runid;
    _ServerConstants.default_depot = defaults.default_depot || 'alloapero.paris';
    _ServerConstants.InfoHours = { 
      opening_to: defaults.opening_to, 
      opening_from: defaults.opening_from, 
      opening_status: defaults.opening_status 
    };
    

    _ServerConstants.ready = true;
    localStorage.setItem('constants', JSON.stringify(_ServerConstants));
    if(_debug) console.log('updated Constants', _ServerConstants);
    // console.log('updated Constants', _ServerConstants);
    cbConstants && cbConstants(_ServerConstants)
  }

  export const LoadConstants = cb => {
    axios({ headers: GetHeaders(), method: 'GET', url: backendHost+'/uisettings' })
    .then(r => parseConstantsFromApiResponse(r, constants => cb(constants)))
    .catch(e => {
      console.log('error loading constants', e)
      cb(null)
    })
  }
  // end Load Constants