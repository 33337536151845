import React from "react";
import styled from "styled-components";

import Helmet from 'react-helmet';

import { Typography } from "@material-ui/core";
import { DownloadCloud as Loader } from "react-feather";
import maTheme from '../../theme';

const Wrapper = styled.div`
  padding: 20px;
  text-align: center;
  background: #fff;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Message = styled.div`
  padding: 20px;
  text-align: center;
  background: #eee;
  min-width: 200px;
  min-height: 200px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;



  // ${props => props.theme.breakpoints.up("md")} {
  //   padding: ${props => props.theme.spacing(10)}px;
  // }

function PageLoading({ message }) {
  return (
    <Wrapper theme={maTheme[0]}>
      <Message>
        <Helmet title="Chargement ..." />
        <Loader style={{
          opacity: 0.2,
          width: '60px',
          height: '60px',
          marginBottom: 20
        }} />

        <Typography component="h5" variant="h5" align="center" gutterBottom style={{opacity: 0.2, fontWeight: 'bold'}}>
          Chargement...
        </Typography>
        <Typography component="h2" variant="h5" align="center" gutterBottom>
          {message}
        </Typography>
        <Typography component="h2" variant="body1" align="center" gutterBottom style={{opacity: 0.2, fontWeight: 'normal'}}>
          Re-charger page <br/>si visible apres 30 secondes
          {/*The page you are looking for might have been removed.*/}
        </Typography>

        {/*
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="secondary"
          mt={2}
        >
          Retour accueil
        </Button>
        */}
      </Message>

    </Wrapper>
  );
}

export default PageLoading;
