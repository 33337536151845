import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useHistory } from "react-router-dom";

import {
  Hidden,
  CssBaseline,
  // Paper as MuiPaper,
  withWidth
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";

const drawerWidthMax = 256;
const drawerWidthMin = 64;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${props => props.theme.breakpoints.up("md")} {
    width: ${props => props.drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

// const Paper = styled(MuiPaper)(spacing);

// const MainContent = styled(Paper)`
const MainContent = styled.div`
  box-sizing: border-box;
  flex: 1;
  background: ${props => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({children, routes, width}) => {
  const mpdDrawerWide = (localStorage.getItem('mpd-drawer-wide') === "false" ? false : true);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [drawerWide, setDrawerWide] = useState(mpdDrawerWide);
  // const { setAuthToken } = useAuth();
  const history = useHistory();

  // console.log('mpdDrawerWide init ls-value:', mpdDrawerWide, 'drawerWide init-value:', drawerWide);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const handleDrawerSizeToggle = (value) => {
    localStorage.setItem('mpd-drawer-wide', value || !drawerWide);

    if(typeof value !== 'undefined')
      setDrawerWide(value)
    else
      setDrawerWide(!drawerWide)
    // if(drawerWidth === drawerWidthMax)
    //   setDrawerWidth(drawerWidthMin)
    // else
    //   setDrawerWidth(drawerWidthMax)

    // console.log('mpdDrawerWide set to (drawerWide)', drawerWide, 'ls-value:', localStorage.getItem('mpd-drawer-wide'))
  };
    
  const handleLogout = () => {
    // setAuthToken(null);
    // SetUser(null);
    history.push('/auth/sign-out');
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer drawerWidth={drawerWide ? drawerWidthMax : drawerWidthMin}>
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWide ? drawerWidthMax : drawerWidthMin } }}

            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            toggleSize={handleDrawerSizeToggle}
            logout={handleLogout}
            compact={!drawerWide}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWide ? drawerWidthMax : drawerWidthMin } }}
            logout={handleLogout}

            toggleSize={handleDrawerSizeToggle}
            compact={!drawerWide}            
          />
        </Hidden>
      </Drawer>
      <AppContent className="AppContent">
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isWidthUp("lg", width) ? 10 : 5} className="MainContent">
          {children}
        </MainContent>
        <Footer onDrawerToggle={handleDrawerToggle} />
      </AppContent>
    </Root>
  )
}

export default withWidth()(Dashboard);
