import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { rgba } from "polished";

import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import { darken } from "polished";
// import axios from 'axios';
import { 
  // SOCKETIO_ENDPOINT,
  DefaultRunidContext,
  ApiCallContext,
  ConstantsContext,
  GetConstantsLabel,
  FriendlyRunid
} from '../config.js';
// import socketIOClient from "socket.io-client";
import { GetUser } from "../Auth.js";
import { UserAvatarImage } from '../components/cdn-image.js'

import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";

import { spacing } from "@material-ui/system";

import {
  // Avatar,
  // Badge,
  Box as MuiBox,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  Grid,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  Tooltip
} from "@material-ui/core";

import { 
  ExpandLess,
  ExpandMore,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";

// import { green } from "@material-ui/core/colors";

import routes from "../routes/index";

import { Truck, Power, User } from "react-feather";

// import socketIOClient from "socket.io-client";
// const Socket = socketIOClient(SOCKETIO_ENDPOINT);

// const Socket = socketIOClient(SOCKETIO_ENDPOINT);
import Emitter from '../eventemitter';

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(6)}px;

  ${props => props.compact &&
    `justify-content: center;`
  }

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const BrandIcon = styled(Truck)`
  color: ${props => props.theme.sidebar.header.brand.color};
`;

const ExpandCollapseMenu = styled.div`
  background-color: ${props => props.theme.sidebar.background};
  display: flex;

  ${props => props.compact 
    ? `
    justify-content: center;
    ` : `
    justify-content: flex-end;
    `
  }
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;

  svg {
    width: 35px;
    height: 35px;
    color: ${props => props.theme.sidebar.color};
    opacity: 0.5;
  }
  svg:hover {
    opacity: 1.0;
  }

`

const LogoutMenu = styled.div`
  background-color: ${props =>
    props.theme.sidebar.footer.background} !important;
  display: flex;

  ${props => props.compact 
    ? `
    justify-content: center;
    ` : `
    justify-content: flex-end;
    `
  }
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;

  svg {
    width: 20px;
    height: 20px;
    color: ${props => props.theme.sidebar.color};
    opacity: 0.5;
  }
  svg:hover {
    opacity: 1.0;
  }

`

const BlankUserMenu = styled.div`
  background-color: ${props =>
    props.theme.sidebar.footer.background} !important;
  display: flex;

  ${props => props.compact 
    ? `
    justify-content: center;
    ` : `
    justify-content: flex-end;
    `
  }
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;

  svg {
    width: 20px;
    height: 20px;
    color: ${props => props.theme.sidebar.color};
    opacity: 0.5;
  }
  svg:hover {
    opacity: 1.0;
  }

`

// const BrandChip = styled(Chip)`
//   background-color: ${green[700]};
//   border-radius: 5px;
//   color: ${props => props.theme.palette.common.white};
//   font-size: 60%;
//   height: 20px;
//   margin-left: 2px;
//   margin-bottom: 1px;
//   padding: 4px 0;

//   span {
//     padding-left: ${props => props.theme.spacing(1.5)}px;
//     padding-right: ${props => props.theme.spacing(1.5)}px;
//   }
// `;

const Category = styled(ListItem)`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-left: ${props => props.theme.spacing(3)}px;

  ${props => props.compact 
    ? `
      width: default;
      min-width: default;
      justify-content: center;
      padding-right: ${props => props.theme.spacing(3)}px;
      svg {
        font-size: 22px;
        width: 22px;
        height: 22px;
      }
    ` : `
      padding-right: ${props => props.theme.spacing(5)}px;
      svg {
        font-size: 20px;
        width: 20px;
        height: 20px;
      }
    `
  }

  font-weight: ${props => props.theme.typography.fontWeightRegular};
  svg {
    color: ${props => props.theme.sidebar.color};
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.sidebar.category.fontWeight};
    padding: 0 ${props => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${props => props.theme.spacing(15)}px;
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;

  span {
    color: ${props => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

  // top: 8px;
  // right: 12px;
const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: relative;
  right: 5px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

  // top: 12px;
const CategoryBadge = styled(LinkBadge)`
`;
  // top: 12px;
const CategoryBadgeRed = styled(LinkBadge)`
  margin-left: -5px;
  background-color: #770000;
`;

const SidebarSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${props =>
    props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(2.75)}px
    ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
  font-size: .725rem;
  display: block;
  padding: 1px;
`;

// const StyledBadge = styled(Badge)`
//   margin-right: ${props => props.theme.spacing(1)}px;
//   span {
//     background-color: ${props => props.theme.sidebar.footer.online.background};
//     border: 1.5px solid ${props => props.theme.palette.common.white};
//     height: 12px;
//     width: 12px;
//     border-radius: 50%;
//   }
// `

function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  badgeRed,
  compact,
  ...rest
}) {
  return (
    <React.Fragment>
      <Tooltip title={name}>
        <span>
  { compact 
    ? <Category 
        compact={compact?"true":"false"} {...rest}>
        {icon}
        {/*
        {isCollapsable ? (
          isOpen ? (
            <CategoryIconMore />
          ) : (
            <CategoryIconLess />
          )
        ) : null}
        */}
        {badge ? <CategoryBadge label={badge} /> : ""}
        {badgeRed ? <CategoryBadgeRed label={badgeRed} /> : ""}
      </Category>
    : <Category {...rest}>
        {icon}
          <CategoryText>{name}</CategoryText>
        {isCollapsable ? (
          isOpen ? (
            <CategoryIconMore />
          ) : (
            <CategoryIconLess />
          )
        ) : null}
        {badge ? <CategoryBadge label={badge} /> : ""}
        {badgeRed ? <CategoryBadgeRed label={badgeRed} /> : ""}
      </Category>
    }
        </span>
      </Tooltip>
    </React.Fragment>
  );
}

function SidebarLink({ name, to, badge, compact }) {
  return (
    <React.Fragment>
    { compact
      ? <Link
          button dense component={NavLink} exact 
          to={to}
          activeClassName="active"
          style={{paddingLeft: 12}}
          >
          <LinkText>{
            name.length > 8 
            ? name.slice(0,8)+'…'
            : name
          }</LinkText>
          {badge ? <LinkBadge label={badge} /> : ""}
        </Link>
      : <Link
          button dense component={NavLink} exact 
          to={to}
          activeClassName="active"
          >
          <LinkText>{name}</LinkText>
          {badge ? <LinkBadge label={badge} /> : ""}
        </Link>
    }
    </React.Fragment> 
  );
}

const GetRunLabel = (runid) => {
  if(runid === 'default')
    return 'default'
  else if(!runid || typeof runid === 'undefined' || runid === 'undefined')
    return '...'
  else
    return FriendlyRunid(runid)
}

// const GetRunLabel = (runid) => {
//   // console.log('getrunlabel', runid)
//   function split(key){
//     return [
//     // '— ',
//     key.split('.')[2].split('-').slice(1,3).join('-'),
//     ' ',
//     key.split('.')[3],
//     ''
//     ].join('')    
//   }

//   if(runid === 'default')
//     return 'default'
//   else if(!runid || typeof runid === 'undefined' || runid === 'undefined')
//     return '...'
//   else
//     return split(runid)
// }

// function Sidebar({ classes, staticContext, location, ...rest }) {
function Sidebar({ 
  classes, staticContext, location, 
  // variant, open, onClose, 
  // routes, PaperProps,
  compact,
  logout,
  toggleSize,
  ...rest  
  }) {

  const currentuser = GetUser();
  // const userScreensHidden = GetUserScreensHidden();
  // console.log('currentuser', currentuser);


  const ApiCall = React.useContext(ApiCallContext);
  const default_runid = useContext(DefaultRunidContext);
  const { Constants } = React.useContext(ConstantsContext);

  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
      // console.log('routes foreach', route, index, Constants.PermissionBlockScreen)
      const isActive = pathName.indexOf(route.sidebar_path || route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      _routes = Object.assign({}, _routes, {[index]: isActive || isOpen || isHome})
    });

    return _routes;
  };
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());
  const [stats, setStats] = useState({
    orders: 0,
    unassigned_orders: 0
  });

  useEffect(() => {
    // Socket.on('update_dashboard_stats', stats => {
    Emitter.on('update_dashboard_stats', stats => {
      // console.log('received signal update_dashboard_stats', stats);
      GetStats(stats);
    });
    GetStats();
    // return () => Socket.disconnect();
    return () => Emitter.off('update_dashboard_stats');
  // eslint-disable-next-line
  }, []);

  // const clickToggleSize = (e) => {
  //   // console.log('sidebar toggleSize', e, this);
  //   // console.log('toggleSize', toggleSize);
  //   // console.log('toggleSize props', rest);
  //   if(toggleSize)
  //     toggleSize(e);
  // }

  function GetStats(stats){
    if(stats)
      return setStats(stats)

    ApiCall({
      url: `/dashboardstats`,
      success: (r) => { 
        if(r && r.data)
          setStats(r.data.DashboardStats)
      },
      error: 'Erreur chargement stats',
      log_label: 'GetStats',
      fin: null
    });
  }

  const toggle = index => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item => openRoutes[index] || setOpenRoutes(openRoutes => Object.assign({}, openRoutes, {[item]: false}))
    )

    // Toggle selected element
    setOpenRoutes(openRoutes => Object.assign({}, openRoutes, {[index]: !openRoutes[index]}));
  }

  const categoryName = (item) => {
    if(item.dynamic === 'manager'){
      if(!default_runid){
        return 'Pas de Journée active';
      } else {
        return `Dispatcher (${GetRunLabel(default_runid || 'default')})`
      }
    } else {
      return item.id;
    }
  }

    // <Drawer variant="permanent" {...rest}>
      // open={open} 
      // PaperProps={PaperProps}
     
      // classes={classes}
      // staticContext={staticContext} 
      // onClose={onClose} 
      // location={location}
      // routes={routes}
      // toggleSize={toggleSize}
  return (
    <Drawer variant="permanent" {...rest} >
      <Brand compact={compact?"true":"false"} >
        { false && 
          <BrandIcon/> 
        }
        { !compact &&
          // <Box ml={1} style={{marginLeft: 10}} >
          <Box ml={1} >
            {Constants.brandname}
            {/*<BrandChip label="dev" />*/}
          </Box>
        }
      </Brand>

      <Scrollbar>
        <List disablePadding>
          <Items>
            {routes.filter(a => !Constants.PermissionBlockScreen[a.permissionName]).map((category, index) => (
              <React.Fragment key={index}>
                {category.header 
                  ? <SidebarSection>{category.header}</SidebarSection>
                  : null
                }

                {category.children ? (
                  <React.Fragment key={index}>
                    <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      name={category.dynamicId ? category.dynamicId() : category.id} 
                      icon={category.icon}
                      // badge={category.badge ? category.badge() : null}
                      button={true}
                      compact={compact}
                      onClick={() => {
                        toggle(index);
                        // toggleSize(true);
                      }}
                    />

                    <Collapse
                      in={openRoutes[index]}
                      timeout="auto"
                      unmountOnExit
                      >
                      {category.children.filter(a => !Constants.PermissionBlockScreen[a.permissionName]).map((route, index) => (
                        <SidebarLink
                          key={index}
                          name={route.dynamicName ? route.dynamicName() : route.name} 
                          to={route.sidebar_path || route.path}
                          icon={route.icon}
                          compact={compact}
                          badge={route.stats1 ? stats[route.stats1] : ''}
                          badgeRed={route.stats2 ? stats[route.stats2] : ''}
                        />
                      ))}
                    </Collapse>
                  </React.Fragment>
                ) : (
                  <SidebarCategory
                    isCollapsable={false}
                    name={ categoryName(category) }
                    to={ category.dynamic === 'manager' 
                      ? `/dispatcher/${ default_runid || 'default' }`
                      : category.sidebar_path || category.path
                    }
                    activeClassName="active"
                    component={ NavLink }
                    icon={ category.icon }
                    exact
                    badge={category.stats1 ? stats[category.stats1] : ''}
                    badgeRed={category.stats2 ? stats[category.stats2] : ''}
                    compact={compact}
                  />
                )}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>


      <ExpandCollapseMenu 
        classes={classes}
        compact={compact}
        onClick={ (e) => toggleSize() }
        {...rest}
        >
        { compact ? <ChevronRight /> : <ChevronLeft /> }
      </ExpandCollapseMenu>
      {/*
      <Category
        button dense 
        component={NavLink} exact 
        to={to}
        activeClassName="active"
        style={{paddingLeft: 12}}
        >
      */}      

      <SidebarFooter>
        <Grid container layout="row" justify={compact?'center':'space-between'} spacing={2}>
          {!compact &&
            <Grid item>
              <Grid container layout="row">     
                <Grid item>
                  { false && currentuser && currentuser.image
                    ? <UserAvatarImage className="avatar" imagekey={ currentuser.image } w="70px" />
                    : <BlankUserMenu
                        classes={classes}
                        >
                        <User />
                      </BlankUserMenu>
                  }
                </Grid>          
                <Grid item style={{marginLeft: 8}}>
                  <SidebarFooterText variant="body2">
                    {currentuser.firstname ? currentuser.firstname.slice(0,1): ''} {currentuser.lastname}
                  </SidebarFooterText>
                  <SidebarFooterSubText variant="caption">
                    {GetConstantsLabel('UserType', currentuser.usertype)}
                  </SidebarFooterSubText>
                </Grid>
              </Grid>
            </Grid>
          }
          <Grid item>
            <LogoutMenu 
              classes={classes}
              compact={compact}
              onClick={ (e) => logout() }
              {...rest}
              >
              <Power />
            </LogoutMenu>
          </Grid>

        </Grid>
      </SidebarFooter>

    </Drawer>
  );
}

export default withRouter(Sidebar);
