import React from "react";
import async from "../components/Async";

import {
  Trello,
  BookOpen,
  Calendar,
  Inbox,
  Home,
  // Heart,
  Package,
  GitPullRequest,
  Truck,
  // ShoppingCart,
  Settings,
  Users
} from "react-feather";

// import {
//   RestaurantMenu
// } from "@material-ui/icons";

// Auth components
const Verify = async(() => import("../pages/auth/Verify"));
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const SignUpDone = async(() => import("../pages/auth/SignUpDone"));
const SignOut = async(() => import("../pages/auth/SignOut"));
const Expired = async(() => import("../pages/auth/Expired"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));

// TODO refactor these message pages
const ResetPasswordDone = async(() => import("../pages/auth/ResetPasswordDone"));
const NewPasswordChanged = async(() => import("../pages/auth/NewPasswordChanged"));
const VerifyDone = async(() => import("../pages/auth/VerifyDone"));
const NewPassword = async(() => import("../pages/auth/NewPassword"));
//

const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

const Dashboard = async(() => import("../pages/dashboard"));

const ListProduct = async(() => import("../pages/ListProduct"));
const ListProductCombo = async(() => import("../pages/ListProductCombo"));
const Product = async(() => import("../pages/Product"));
const ProductCombo = async(() => import("../pages/ProductCombo"));
const ListProductMenu = async(() => import("../pages/ListProductMenu"));
const EditProductMenu = async(() => import("../pages/EditProductMenu"));


const ListStockLevel = async(() => import("../pages/ListStockLevel"));
const ListStockPurchasing = async(() => import("../pages/ListStockPurchasing"));
const StockPurchasing = async(() => import("../pages/StockPurchasing"));
const StocktakeSimple = async(() => import("../pages/StocktakeSimple"));
const ListSupplier = async(() => import("../pages/ListSupplier"));
const Supplier = async(() => import("../pages/Supplier"));

const ListOrder = async(() => import("../pages/ListOrder"));
const Order = async(() => import("../pages/Order"));
const ListCustomer = async(() => import("../pages/ListCustomer"));
const Customer = async(() => import("../pages/Customer"));

const ListProductTag = async(() => import("../pages/ListProductTag"));
const ListPurchaseStatus = async(() => import("../pages/ListPurchaseStatus"));
const ListSalesTax = async(() => import("../pages/ListSalesTax"));
const ListPaymentInstrument = async(() => import("../pages/ListPaymentInstrument"));
const ListDeliveryCharge = async(() => import("../pages/ListDeliveryCharge"));
const ListConstant = async(() => import("../pages/ListConstant")); 
const EditConstant = async(() => import("../pages/EditConstant"));

const ListCar = async(() => import("../pages/ListCar"));
const EditCar = async(() => import("../pages/EditCar"));
const ListUser = async(() => import("../pages/ListUser"));
const User = async(() => import("../pages/User"));

const ListRun = async(() => import("../pages/ListRun"));
const ListRunTemplate = async(() => import("../pages/ListRunTemplate"));
const Dispatcher = async(() => import("../pages/Dispatcher"));
const EditRun = async(() => import("../pages/EditRun"));

const ListTour = async(() => import("../pages/ListTour"));
const ListTourTemplate = async(() => import("../pages/ListTourTemplate"));
const EditTour = async(() => import("../pages/Tour"));

const ListMessagesDriverAndDelay = async(() => import("../pages/ListMessagesDriverAndDelay"));
const ListMessagesManager = async(() => import("../pages/ListMessagesManager"));

// const Setting = async(() => import("../pages/Setting"));
const ListNetwork = async(() => import("../pages/ListNetwork"));
const EditNetwork = async(() => import("../pages/EditNetwork"));

const Defaults = async(() => import("../pages/Defaults"));
const OpenStatus = async(() => import("../pages/OpenStatus"));
const DataOperations = async(() => import("../pages/DataOperations"));
// const Maintenance = async(() => import("../pages/Maintenance"));


const dashboardsRoutes = {
  id: "Tableau de Bord",
  path: "/dashboard",
  icon: <Home />,
  // stats1: 'orders',
  // stats2: 'unassigned_orders',
  component: Dashboard,
  children: null
};


const dispatcherRoutes = {
  id: "Dispatcher",
  dynamic: 'manager',
  path: '/dispatcher/:runid',
  icon: <Trello />,
  permissionName: 'Dispatcher',
  component: Dispatcher,
  children: null
};


const productsRoutes = {
  id: "Articles",
  path: "/products",
  icon: <BookOpen />,
  permissionName: 'ProductGroup',
  // component: Run,
  children: [
    {
      path: "/products",
      name: "Articles",
      permissionName: 'ProductList',
      component: ListProduct,
    }, {
      path: "/productcombos",
      name: "Formules",
      permissionName: 'ListProductCombo',
      component: ListProductCombo,
    }, {
      path: "/products/menus",
      name: "Menus par réseau",
      permissionName: 'ProductMenus',
      component: ListProductMenu
    }, {
      path: "/products/tags",
      permissionName: 'ProductTags',
      name: "Tags ⚙",
      component: ListProductTag
    }
  ]
};
const productRoutes = {
  id: "Article",
  path: "/product/:productid",
  component: Product,
  permissionName: 'ProductEdit',
  children: null
};
const productComboRoutes = {
  id: "Formule",
  path: "/productcombo/:productcomboid",
  component: ProductCombo,
  permissionName: 'ProductComboEdit',
  children: null
};
const editConstantsRoutes = {
  id: "Paramètre",
  path: "/settings/system/:constantid",
  permissionName: 'SettingsEdit',
  component: EditConstant,
  children: null
};


const stocksRoutes = {
  id: "Achats & Stocks",
  path: "/stocks",
  permissionName: 'StocksGroup',
  icon: <Package />,
  // component: Run,
  children: [
    {
      path: "/stocks",
      name: "Stocks",
      permissionName: 'ListStockLevel',
      component: ListStockLevel
    }, {
      path: "/stocks/purchasing",
      name: "Achats & Réceptions",
      permissionName: 'ListStockPurchasing',
      component: ListStockPurchasing
    // }, {
    //   path: "/stocks/purchasing/:purchaseid",
    //   name: "Achats & Réceptions",
    //   component: ListStockPurchasing
    }, {
      path: "/stocks/purchase_status",
      name: "Statuts des achats ⚙",
      permissionName: 'ListPurchaseStatus',
      component: ListPurchaseStatus
    }, {
      path: "/stocks/stocktake",
      name: "Rélévé d'Inventaire",
      permissionName: 'StocktakeSimple',
      component: StocktakeSimple
    }, {
      path: "/stocks/suppliers",
      name: "Fournisseurs",
      permissionName: 'ListSupplier',
      component: ListSupplier
    }
  ]
};
const supplierRoutes = {
  id: "Fournisseur",
  path: "/supplier/:supplierid",
  component: Supplier,
  permissionName: 'SupplierGroup',
  children: null
};


const salesRoutes = {
  id: "Ventes",
  path: "/sales",
  icon: <Inbox />,
  permissionName: 'SalesGroup',
  // component: Run,
  children: [
    {
      path: "/sales/orders",
      name: "Commandes",
      permissionName: 'ListOrder',
      component: ListOrder
    }, {      
      path: "/order/new",
      name: "Nouvelle Commande ➕",
      permissionName: 'OrderEdit',
      component: Order
    }, {
      path: "/sales/customers",
      name: "Clients",
      permissionName: 'ListCustomer',
      component: ListCustomer
    }, {
      path: "/sales/networks",
      name: "Réseaux de distribution",
      permissionName: 'ListNetwork',
      component: ListNetwork
    }, {
      path: "/sales/payment_instrument",
      name: "Modes de règlement ⚙",
      permissionName: 'ListPaymentInstrument',
      component: ListPaymentInstrument
    }, {
      path: "/sales/sales_tax",
      name: "Taux de TVA ⚙",
      permissionName: 'ListSalesTax',
      component: ListSalesTax
    }, {
      path: "/sales/delivery",
      name: "Frais de livraison",
      permissionName: 'ListDeliveryCharge',
      component: ListDeliveryCharge
    }
  ]
};
const orderRoutes = {
  id: "Commandes",
  path: "/order/:orderid",
  component: Order,
  permissionName: 'OrderEdit',
  children: null
};
const clientRoutes = {
  id: "Client",
  path: "/customer/:userid",
  component: Customer,
  permissionName: 'CustomerEdit',
  children: null
};
const purchasingRoutes = {
  id: "Achats",
  path: "/stocks/purchasing/:purchasingid",
  component: StockPurchasing,
  permissionName: 'StockPurchasing',
  children: null
};
// const deliveryChargeRoutes = {
//   id: "Taux de TVA",
//   path: "/delivery_price/:constantid",
//   permissionName: 'DeliveryChargeEdit',
//   component: DeliveryCharge,
//   children: null
// };


const deliveryRoutes = {
  id: "Tournées & Livraisons",
  path: "/delivery",
  permissionName: 'ToursGroup',
  icon: <Calendar />,
  // component: Run,
  children: [
    {
      path: "/delivery/runs",
      name: "Journées",
      permissionName: 'ListRun',
      component: ListRun
    }, {
      path: "/delivery/runtemplates",
      name: "Journées Type",
      permissionName: 'ListRunTemplate',
      component: ListRunTemplate
    }, {
      path: "/delivery/tours",
      name: "Tournées",
      permissionName: 'ListTour',
      component: ListTour
    }, {
      path: "/delivery/tourstemplates",
      name: "Tournées Type",
      permissionName: 'ListTourTemplate',
      component: ListTourTemplate
    }, {
    //   path: "/run/:runid",
    //   dynamic: 'manager',
    //   name: "Dispatcher",
    //   component: Run
    // }, {
      path: "/delivery/messagetemplates/driver-and-delay",
      name: "Anomalies",
      permissionName: 'ListMessagesDriverAndDelay',
      component: ListMessagesDriverAndDelay
    }, {
      path: "/delivery/messagetemplates/manager",
      name: "Ordres spéciaux",
      permissionName: 'ListMessagesManager',
      component: ListMessagesManager
    }, {
      path: "/delivery/cars",
      name: "Véhicules",
      permissionName: 'ListCar',
      component: ListCar
    }
    // , {
    //   path: "/users?mode=drivers",
    //   name: "Livreurs",
    //   component: ListUser
    // }
  ]
};

// TODO rework the routes here... looks like garbage

const runRoutes = {
  id: "Journée",
  path: "/run/:runid",
  icon: <GitPullRequest />,
  component: EditRun,
  permissionName: 'EditRun',
  children: null
};
const tourRoutes = {
  id: "Tournée",
  path: "/tour/:tourid",
  icon: <GitPullRequest />,
  component: EditTour,
  permissionName: 'EditTour',
  children: null
};
const carRoutes = {
  id: "Véhicule",
  path: "/car/:carid",
  icon: <Truck />,
  permissionName: 'EditCar',
  component: EditCar,
  children: null
};
const productMenuRoutes = {
  id: "Ménu",
  path: "/products/menus/:productid",
  icon: <Truck />,
  permissionName: 'EditProductMenu',
  component: EditProductMenu,
  children: null
};
const networkRoutes = {
  id: "Réseau Partenaire",
  path: "/sales/networks/:networkid",
  icon: <Truck />,
  permissionName: 'EditNetwork',
  component: EditNetwork,
  children: null
};

// const ListRunRoutes = {
//   id: "Journées",
//   path: "/runs",
//   icon: <Calendar />,
//   component: ListRun,
//   children: null
// };
// const ListTourRoutes = {
//   id: "Tournées",
//   path: "/tours",
//   icon: <GitPullRequest />,
//   component: ListTour,
//   children: null
// };


const ListUserRoutes = {
  id: "Utilisateurs",
  path: "/users",
  icon: <Users />,
  permissionName: 'ListUser',
  component: ListUser,
  children: null
};
const userRoutes = {
  id: "Utilisateur",
  path: "/user/:userid",
  permissionName: 'UserEdit',
  component: User,
  children: null
};


const settingRoutes = {
  id: "Paramètres",
  path: "/settings",
  permissionName: 'SettingsGroup',
  icon: <Settings />,
  // component: Run,
  children: [
    {
      path: "/settings/hours",
      name: "Ouverture",
      permissionName: 'OpenStatus',
      component: OpenStatus
    }, {
      path: "/settings/defaults",
      name: "Service",
      permissionName: 'Defaults',
      component: Defaults
    }, {
      path: "/settings/data",
      name: "Opérations Données",
      permissionName: 'DataOperations',
      component: DataOperations
    }, {
      path: "/settings/system",
      name: "Tout Paramètre Système ⚙",
      permissionName: 'ListConstant',
      component: ListConstant
    // }, {
    //   path: "/settings/maintenance",
    //   name: "Maintenance",
    //   component: Maintenance
    }
  ]
};
// const settingSingleRoutes = {
//   id: "Paramètre",
//   path: "/setting/:constantid",
//   permissionName: 'SettingsSingleEdit',
//   component: Setting,
//   children: null
// };


const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/v",
      name: "Verify Email or SMS",
      component: Verify
    }, {
      path: "/v/done",
      name: "Verification complete",
      component: VerifyDone
    }, {
      path: "/v/:token",
      name: "Verify Email or SMS",
      component: Verify
    }, {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    }, {
      path: "/auth/sign-out",
      name: "Sign Out",
      component: SignOut
    }, {
      path: "/auth/expired",
      name: "Expired",
      component: Expired
    }, {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp
    }, {
      path: "/auth/sign-up/requested",
      name: "Sign Up Done",
      component: SignUpDone
    }, {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    }, {
      path: "/auth/requested",
      name: "Password Request Submitted",
      component: ResetPasswordDone
    }, {
      path: "/auth/new-password",
      name: "New Password",
      component: NewPassword
    }, {
      path: "/auth/changed",
      name: "Password Changed",
      component: NewPasswordChanged
    }, {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    }, {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

//
// all available routes for the router
//
export const dashboard = [
  dashboardsRoutes,

  dispatcherRoutes,

  productsRoutes,
  productRoutes,
  productComboRoutes,
  editConstantsRoutes,
  productMenuRoutes,
  networkRoutes,

  stocksRoutes,
  supplierRoutes,

  salesRoutes,
  orderRoutes,
  clientRoutes,
  purchasingRoutes,
  // purchaseStatusRoutes,
  // paymentInstrumentRoutes,
  // salesTaxRoutes,
  // deliveryChargeRoutes,

  deliveryRoutes,
  runRoutes,
  tourRoutes,
  carRoutes,

  ListUserRoutes,
  userRoutes,

  settingRoutes,
];

export const auth = [authRoutes];

//
// displayed on sidebar
// 
const sidebarRoutes = [
  dashboardsRoutes,
  dispatcherRoutes,
  productsRoutes,
  // productComboRoutes,
  stocksRoutes,
  salesRoutes,
  deliveryRoutes,
  ListUserRoutes,
  settingRoutes,
];

export default sidebarRoutes;